* {
  box-sizing: border-box;
}

body, html {
  min-height: 100%;
  padding: 0;
}

.title_container {
  display: flex;
  flex-direction: column;
  line-height: 2em;
}

#gradient_animation {
top: 0px;
position: absolute;
min-width: 100%;
min-height: 100%;
margin: 0;
padding: 0;
will-change: opacity;
    transition: opacity 0.3s ease-out;
    background-color: #9fc;
    background-image: linear-gradient(to top,#00FFE2 0%,#19C8FE 50%, #00FFE2 100%);
    -webkit-background-size: 100% 400%;
    -moz-background-size: 100% 400%;
    background-size: 100% 400%;
    -webkit-animation: grad-anim 21s infinite linear;
    -moz-animation: grad-anim 21s infinite linear;
    animation: grad-anim 21s infinite linear;
}

@-webkit-keyframes grad-anim{to{background-position:0 400%;}}@-moz-keyframes grad-anim{to{background-position:0 400%;}}@keyframes grad-anim{to{background-position:0 400%;}}

 .mainh {
    z-index: 1;
    color: white;
    line-height: 1.5em;
    position: relative;
    margin: 0;
    top: 25vh;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 9vh;
}

span {
    box-sizing: content-box;
    z-index: 2;
    background-color: black;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 25px;
    padding-right: 25px;
}
