$animation-delay: .1s;
$arrow-size: 15px;

.arrow-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  // width: 1.5em;
  transform: rotate(270deg);
}

#arrow-conatiner {
  box-sizing: content-box;
  z-index: 2;
  max-width: 1em;
  margin: 0 auto;
  background-color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 25px;
  padding-right: 25px;
}

.scroll-arrow {
  width: $arrow-size;
  height: $arrow-size;
  transform: rotate(45deg);
  border-right: 4px solid white;
  border-bottom: 4px solid white;
  animation: arrow-wave 1s infinite;
  animation-direction: alternate;
  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation-delay: $animation-delay*$i;
    }
  }
}

@keyframes arrow-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}
