@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,500,600,700,800,800i');

html {
  width: 100%;
  height: 100%;
}

#title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

.italic {
  font-style: italic;
}
