@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,500,600,700,800,800i');

html {
  width: 100%;
  height: 100%;
  background-color: black;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-style: italic;
}

#container {
  margin: 5%;
}

#container > a:link, a, p {
  padding: 0;
  line-height: 2em;
}

a:hover {
  -webkit-text-fill-color: white;
  background-clip: text;
  text-decoration: none;
}

.bold-font {
  padding: 0;
  font-weight: 600;
}

.gradient-letters {
  background: transparent;
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #61c2ff), color-stop(100%, #cd4444));
  background: -webkit-linear-gradient(-45deg, #61c2ff 0%, #cd4444 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-styling {
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: 2em;
  text-decoration: none;
}
