@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,500,600,700,800,800i);
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,500,600,700,800,800i);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.arrow-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

#arrow-conatiner {
  box-sizing: content-box;
  z-index: 2;
  max-width: 1em;
  margin: 0 auto;
  background-color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 25px;
  padding-right: 25px; }

.scroll-arrow {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-right: 4px solid white;
  border-bottom: 4px solid white;
  -webkit-animation: arrow-wave 1s infinite;
          animation: arrow-wave 1s infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate; }
  .scroll-arrow:nth-child(1) {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .scroll-arrow:nth-child(2) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .scroll-arrow:nth-child(3) {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }

@-webkit-keyframes arrow-wave {
  0% {
    opacity: 0; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

@keyframes arrow-wave {
  0% {
    opacity: 0; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

* {
  box-sizing: border-box;
}

body, html {
  min-height: 100%;
  padding: 0;
}

.title_container {
  display: flex;
  flex-direction: column;
  line-height: 2em;
}

#gradient_animation {
top: 0px;
position: absolute;
min-width: 100%;
min-height: 100%;
margin: 0;
padding: 0;
will-change: opacity;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    background-color: #9fc;
    background-image: -webkit-gradient(linear,left bottom, left top,from(#00FFE2),color-stop(50%, #19C8FE), to(#00FFE2));
    background-image: -webkit-linear-gradient(bottom,#00FFE2 0%,#19C8FE 50%, #00FFE2 100%);
    background-image: linear-gradient(to top,#00FFE2 0%,#19C8FE 50%, #00FFE2 100%);
    background-size: 100% 400%;
    -webkit-animation: grad-anim 21s infinite linear;
    animation: grad-anim 21s infinite linear;
}

@-webkit-keyframes grad-anim{to{background-position:0 400%;}}@keyframes grad-anim{to{background-position:0 400%;}}

 .mainh {
    z-index: 1;
    color: white;
    line-height: 1.5em;
    position: relative;
    margin: 0;
    top: 25vh;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 9vh;
}

span {
    box-sizing: content-box;
    z-index: 2;
    background-color: black;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 25px;
    padding-right: 25px;
}

html {
  width: 100%;
  height: 100%;
  background-color: black; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-style: italic; }

#container {
  margin: 5%; }

#container > a:link, a, p {
  padding: 0;
  line-height: 2em; }

a:hover {
  -webkit-text-fill-color: white;
  -webkit-background-clip: text;
          background-clip: text;
  text-decoration: none; }

.bold-font {
  padding: 0;
  font-weight: 600; }

.gradient-letters {
  background: transparent;
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #61c2ff), color-stop(100%, #cd4444));
  background: -webkit-linear-gradient(-45deg, #61c2ff 0%, #cd4444 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }

.text-styling {
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: 2em;
  text-decoration: none; }

html {
  width: 100%;
  height: 100%;
}

#title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

.italic {
  font-style: italic;
}

